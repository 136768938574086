.Project__ListHeader {
  padding-left: 2rem;
}

.Project__List {
  overflow: auto;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  height: 200px;
}

.Project__List::-webkit-scrollbar {
  display: none;
}

.Project {
  opacity: 0.5;
  max-width: 200px;
  transition: opacity 0.5s ease-in-out;
  cursor: pointer;

  flex-shrink: 0;
  scroll-snap-align: start;
  box-sizing: border-box;
  user-select: none;
}

.Project:hover {
  opacity: initial;
}

.Project.Focused {
  opacity: initial;
  max-width: 220px;
}

.Project.Focused > img {
  border: 1px solid var(--color-white);
}

.Project__Description {
  background-color: var(--color-faded-grey);
  padding: 1.5rem;
  border-radius: 10px;
}
