.Container {
  background-color: var(--color-faded-grey);
  border-radius: 10px;
  padding: 1rem;
}

.Container.Focused {
  background-color: var(--color-blue);
  box-shadow: 4px 4px var(--color-white);
}
