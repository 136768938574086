:root {
  --color-white: #fff;
  --color-black: #000;
  --color-blue: #3744fc;
  --color-yellow: rgb(255, 242, 128);
  --color-faded-grey: rgba(30, 30, 30, 0.8);

  --background: url('./assets/bg/default.webp');
  --darken: 0.8;
}

body {
  color: var(--color-white);
  background-color: #222;
  background-image: var(--background);
  background-size: cover;
  background-position: center;
}

#root {
  background-color: rgba(0, 0, 0, var(--darken));
  background-size: cover;
}

.Inner {
  display: grid;
  grid-template-rows: max-content 1fr;

  max-width: 1280px;
  min-height: 100vh;

  box-sizing: border-box;
  padding: 0 1rem;
  margin: 0 auto;
}

main {
  padding-top: 0.5rem;
}

.s {
  font-size: 0.8rem;
}

.l {
  font-size: 1.2rem;
}

.xl {
  font-size: 1.5rem;
}

.b {
  font-weight: bold;
}

.i {
  font-style: oblique;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-shadow {
  text-shadow: 1px 1px var(--color-black);
}

.circle {
  border-radius: 50%;
}

.responsive-img {
  display: block;
  max-width: 100%;
  height: auto;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.caret {
  overflow: hidden;
}

.click-icon {
  cursor: pointer;
  user-select: none;
  text-shadow: 2px 2px var(--color-black);
}
