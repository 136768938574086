.About {
}

.About__Container {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 2rem;
}

.About__CharacterBubble {
  background-color: var(--color-yellow);
  box-shadow: 0 0 5px 1px var(--color-yellow);
  width: 150px;
  border-radius: 50%;
}

.About__SkillSummary {
  gap: 50px;
}

@media only screen and (min-width: 768px) {
  .About__Container {
    flex-direction: row;
    justify-content: center;
    text-align: start;
  }
}
