.ProgressBar {
  display: flex;
  justify-content: space-between;
  padding: 0.9rem;
  cursor: pointer;
}

.ProgressBar:hover {
  background-color: var(--color-faded-grey);
}

.ProgressBar.Focused {
  background-color: var(--color-blue);
  position: relative;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .ProgressBar {
    transition: background-color 0.2s ease-in-out;
  }
}
