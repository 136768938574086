.Avatar {
  display: block;
  max-width: 100%;

  width: 3rem;
  height: auto;

  box-sizing: border-box;
  user-select: none;
}

.FocusableAvatar {
  cursor: pointer;
}

.FocusableAvatar:hover {
  transform: scale(1.1);
}

.FocusableAvatar.Focused {
  border-radius: 0;
  outline: 1px solid var(--color-white);
  box-shadow: 0 0 10px 2px var(--color-blue);
}

@media screen and (prefers-reduced-motion: no-preference) {
  .FocusableAvatar {
    transition: all 0.1s ease-in-out;
  }
}
