.Button {
  color: var(--color-white);
  background-color: var(--color-faded-light);
  border: 1px solid var(--color-white);

  padding: 0.5rem 1.2rem;
  cursor: pointer;
  outline: none;
}

.Button:hover {
  background-color: var(--color-faded-grey);
  text-decoration: underline;
}

.Button.Focused {
  transform: translate(-4px, -4px);
  background-color: var(--color-blue);
  border: 1px solid var(--color-blue);
  box-shadow: 8px 8px var(--color-white);
}

.TopLeftButton {
  color: var(--color-white);
  background-color: transparent;
  border: none;
  outline: none;

  padding: 4px 8px;

  position: absolute;
  top: 10%;
  left: 10%;
  cursor: pointer;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .Button {
    transition: background-color 0.2s ease-in-out;
  }
}
