.Skills__Page {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Skills__Column {
  background-color: var(--color-faded-grey);
  padding: 1.4rem;
  border-radius: 10px;
  white-space: pre-wrap;
}

.SoftSkill {
  background-color: var(--color-faded-grey);
  border-radius: 10px;
  padding: 0.8rem;
}

@media only screen and (min-width: 900px) {
  .Skills__Page {
    display: grid;
    grid-template-columns: 0.55fr 0.4fr;
    grid-auto-rows: min-content;
    justify-content: space-between;
    gap: 20px;
  }
}

@keyframes Filler {
  0% {
    width: 0;
  }
  100% {
    width: var(--data-width);
  }
}
