.Header {
  margin-top: 5px;
  border-radius: 10px;
  background-color: var(--color-faded-grey);
  overflow-x: auto;
  padding: 0.5rem 1rem;
}

/* User */
.Header__User {
  min-width: max-content;
  cursor: pointer;
}

.Header__User.Focused .Avatar {
  border-radius: 0px;
  outline: 1px solid var(--color-white);
}

.Header__UserName,
.Header__Clock {
  display: none;
}

/* Navigation Menu */
.Nav__Item {
  color: var(--color-white);
  padding: 1rem;
  cursor: pointer;
  opacity: 0.7;
}

.Nav__Item:hover {
  opacity: initial;
  text-shadow: 2px 2px var(--color-faded-grey);
}

.Nav__Item.Focused {
  opacity: initial;
  background-color: var(--color-blue);
  box-shadow: 8px 8px var(--color-white);
  transform: translate(-4px, -4px);
}

@media only screen and (min-width: 768px) {
  .Header__User {
    padding: 0.5rem 1rem;
  }

  .Header__User.Focused {
    background-color: var(--color-blue);
    outline: 1px solid var(--color-blue);
  }

  .Header__UserName,
  .Header__Clock {
    display: block;
    white-space: nowrap;
  }

  .Nav__Item {
    text-transform: uppercase;
  }
}

@media only screen and (min-width: 900px) {
  .Nav__Item {
    padding: 0.5rem 3rem;
  }
}

@media screen and (prefers-reduced-motion: no-preference) {
  .Header__User .Avatar {
    transition: border-radius 0.1s ease-in-out;
  }
  .Nav__Item {
    transition: opacity 0.1s ease-in-out;
  }
}
