.Contact {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.Contact__Work {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 2rem;

  background-color: var(--color-faded-grey);
  padding: 2rem;
  border-radius: 10px;
}

.Contact__WorkColumn {
  text-align: center;
}

.Contact__Links {
  display: grid;
  grid-template-columns: repeat(4, 0.15fr);
  justify-content: center;
  gap: 20px;
}

.Contact__Link {
  aspect-ratio: 1;
  background-color: var(--color-faded-grey);
  border: none;
}

.Contact__Link:hover {
  color: var(--color-blue);
  background-color: rgb(30, 30, 30);
}

.Contact__Link.Focused {
  color: var(--color-blue);
  background-color: var(--color-white);
  box-shadow: 0 0 10px var(--color-white);
  border: none;
}

.Contact__Link svg {
  width: 50%;
  height: 50%;
}
