.PreviewFrame__Container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  user-select: none;
}

.PreviewFrame__Darken {
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
}

.PreviewFrame__Btn {
  color: var(--color-black);
  background-color: var(--color-white);
  border-radius: 10px;

  padding: 0.5rem 0.75rem;

  opacity: 0.75;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  z-index: 1;
}

.PreviewFrame__Inner {
  z-index: 1;
}

.PreviewFrame__Btn:hover {
  opacity: initial;
}

.PreviewFrame {
  background-color: var(--color-white);

  width: 80vw;
  height: 80vh;
  overflow-x: hidden;

  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}
