.Character__Container {
  display: grid;
  grid-template-columns: 0.2fr 0.8fr;
  padding: 0 2rem 2rem 2rem;
  column-gap: 10px;
  align-self: flex-end;
}

.Character {
  user-select: none;
  object-fit: scale-down;

  background-color: var(--color-blue);
  border-radius: 40%;
}

.Character__Dialogue {
  color: var(--color-black);
  background-color: var(--color-white);
  box-shadow: 0.5rem 0.5rem var(--color-blue);

  border-radius: 11px;
  padding: 1rem;
}

.Character__Skip {
  color: var(--color-faded-grey);
}

@media only screen and (min-width: 900px) {
  .Character__Container {
    align-items: flex-start;
    gap: 20px;
  }

  .Character__Dialogue {
    position: relative;
    box-shadow: 20px 20px var(--color-blue);
    padding: 3rem;
  }

  .Character__Dialogue:after {
    content: '.';
    font-size: 3.5rem;
    font-weight: bold;
    -webkit-text-fill-color: var(--color-white);

    position: absolute;
    width: 55px;
    height: 11px;
    bottom: -30px;
    left: 0;

    line-height: 30px;
    border-radius: 1rem;
    background-color: var(--color-white);
    text-shadow: 22px 22px var(--color-blue);
    box-shadow: 22px 22px var(--color-blue);
    z-index: -2;
  }

  @media screen and (prefers-reduced-motion: no-preference) {
    .Character.Character__Talking {
      animation: bounce 0.4s ease-in-out;
    }

    .Character__Dialogue {
      transform: translateY(0px);
      animation: float 5s ease-in-out infinite;
    }

    .Character__Dialogue:after {
      transform: translateY(0px);
      animation: float2 5s ease-in-out infinite;
    }
  }
}

@keyframes bounce {
  0%,
  100%,
  50% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-12px);
    -ms-transform: translateY(-12px);
    transform: translateY(-12px);
  }
  75% {
    -webkit-transform: translateY(-8px);
    -ms-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes float2 {
  0% {
    line-height: 30px;
    transform: translateY(0px);
  }
  55% {
    transform: translateY(-20px);
  }
  60% {
    line-height: 10px;
  }
  100% {
    line-height: 30px;
    transform: translateY(0px);
  }
}
