/* Responsive text */
html {
  font-size: 14px;
}

@media only screen and (min-width: 540px) {
  html {
    font-size: 15px;
  }
}

@media only screen and (min-width: 900px) {
  html {
    font-size: 17px;
  }
}

/* Global styles */
body {
  font-size: 1rem;
  font-family: 'Baloo 2', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  margin: 0;
}

button {
  font-family: 'Baloo 2', sans-serif;
  font-size: 1rem;
}

/* Flex Classes */
.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.justify-left {
  justify-content: left;
}

.justify-right {
  justify-content: right;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.gap {
  gap: 10px;
}

/* Global Animations */
@media screen and (prefers-reduced-motion: no-preference) {
  .FadeIn {
    animation: fadeIn 0.5s ease-in;
  }

  .caret::after {
    content: '|';
    animation: caret 1.1s infinite step-start;
  }
}

@keyframes caret {
  50% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: initial;
  }
}
