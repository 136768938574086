.Link {
  color: var(--color-white);
  border: 1px solid var(--color-white);
  text-decoration: none;

  padding: 0.5rem 1.2rem;
}

.Link:hover {
  color: var(--color-white);
  background-color: var(--color-faded-grey);
  text-decoration: underline;
}

.Link.Focused {
  transform: translate(-4px, -4px);
  background-color: var(--color-blue);
  border: 1px solid var(--color-blue);
  box-shadow: 8px 8px var(--color-white);
}

@media screen and (prefers-reduced-motion: no-preference) {
  .Link {
    transition: color 0.3s ease-in-out;
  }
}
