.Login {
  height: 100vh;
}

.Login__Inner {
  padding: 40px;
  border-radius: 10px;
}

.Login__Form {
  gap: 20px;
}

.Login__IconList {
  gap: 2rem;
}

.Login__IconChosen {
  width: 6rem;
  height: 6rem;
}

.Login__Icon {
  width: 4rem;
  height: 4rem;
}

.Login__Btn {
  box-shadow: 0 0 5px 1px var(--color-black);
}
