.TextField {
  color: var(--color-faded-grey);
  font-size: 0.9rem;
  border: none;
  outline: none;
  padding: 0.8rem;
}

.TextField.Focused {
  color: var(--color-black);
  box-shadow: 4px 4px var(--color-blue);
}
