.Dropdown {
  display: flex;
  justify-content: center;
  align-items: center;

  perspective: 1000px;
  z-index: 100;

  list-style-type: none;
  position: relative;
}

.Dropdown__Menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;

  perspective: 1000px;
  z-index: -1;
}

.Dropdown__Menu > li {
  display: none;
  background-color: var(--color-faded-grey);
  padding: 0.5rem 1rem;
  opacity: 0;
}
